const AdComponent = ({ index }) => {
  const adIndex = Math.floor(index / 6) % 6
  const ads = [
    'https://g.adspeed.net/ad.php?do=html&aid=1126143&oid=29387&wd=1080&ht=1080&target=_blank',
    'https://g.adspeed.net/ad.php?do=html&aid=1126144&oid=29387&wd=1080&ht=1080&target=_blank',
    'https://g.adspeed.net/ad.php?do=html&aid=1130622&oid=29387&wd=1080&ht=1080&target=_blank',
    'https://g.adspeed.net/ad.php?do=html&aid=1126146&oid=29387&wd=1080&ht=1080&target=_blank',
    'https://g.adspeed.net/ad.php?do=html&aid=1122374&oid=29387&wd=1080&ht=1080&target=_blank',
    'https://g.adspeed.net/ad.php?do=html&aid=1130611&oid=29387&wd=1080&ht=1080&target=_blank'
  ]
  return (
    <div id={`ad-${index}-${adIndex}`} className="ad-container border-b pb-6 mb-6 border-gray-100">
      <p className="text=xs">Advertisement</p>
      <iframe
        title={`ad-${index}-${adIndex}`}
        width="1080"
        height="1080"
        src={ads[adIndex]}
        frameBorder="0"
        scrolling="no"
        allowtransparency="true"
        hspace="0"
        vspace="0"
        style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}
      ></iframe>
    </div>
  )
}

export default AdComponent
